<template>
  <div class="edit-div">
    <div v-if="!successSave">
      <div
        class="mail-validation-message"
        v-if="contractContent.preagency.contractInfo.mailValidation.state"
      >
        <span
          >{{ $t("label.mailValidation") }}
          {{
            new Date(
              contractContent.preagency.contractInfo.mailValidation.date
            ).getDate()
          }}/{{
            new Date(
              contractContent.preagency.contractInfo.mailValidation.date
            ).getMonth()
          }}/{{
            new Date(
              contractContent.preagency.contractInfo.mailValidation.date
            ).getFullYear()
          }}</span
        >
      </div>
      <div class="contract-container">
        <div
          class="read-text"
          @scroll="checkHeight()"
          ref="readText"
          v-html="contractContent.html"
        ></div>
        <div class="contract-validation">
          <label for="contract-check">
            {{ $t("label.RaCheck") + " " + $t("label.contract") }}
          </label>
          <input
            type="checkbox"
            v-model="acceptContract"
            id="contract-check"
            :disabled="
              contractNotRead ||
              contractContent.preagency.contractInfo.webValidation.state
            "
            :checked="
              contractContent.preagency.contractInfo.webValidation.state
            "
          />
        </div>
      </div>
      <div class="buttons">
        <router-link
          class="download-button"
          :to="
            '/download/contract/' +
            this.$route.params.id +
            '?pass=' +
            this.$route.query.pass
          "
          target="_top"
          style="
            background-color: rgb(9, 201, 111);
            color: white;
            border: none;
            border-radius: 10px;
            margin: 10px auto;
            font-weight: bold;
            padding: 5px 20px;
            font-size: 20px;
          "
          >{{ $t("buttons.downloadContract") }}</router-link
        >
        <button
          id="confirmation-button"
          @click="submitEdit"
          :disabled="
            checkForm || this.$store.getters.userType == 'travelAssistEmployee'
          "
          class="save-button"
          v-if="!contractContent.preagency.contractInfo.webValidation.state"
        >
          {{ $t("buttons.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "AgencyContract",
  data() {
    return {
      agency: false,
      acceptContract: false,
      successSave: false,
      countdown: 10,
      contractNotRead: true,
      contractUrl:
        "https://matrix.travelassist.fr//_matrix/media/r0/download/travelassist.fr/tHAXvTPvYEQiwESbknungPeM",
      contractContent: {
        preagency: {
          contractInfo: {
            webValidation: {
              state: false,
              date: 0,
            },
            mailValidation: {
              state: false,
              date: 0,
            },
          },
        },
      },
      mailValidation: false,
    };
  },
  computed: {
    checkForm: function() {
      if (!this.acceptContract) {
        return true;
      }

      return false;
    }
  },
  methods: {
    submitEdit: async function () {
      if (!this.agency) {
        try {
          this.$store.dispatch("load", this.$t("loadMessage.saving"));

          let webValidationData = {
            contractInfo: {
              webValidation: {
                state: true,
                date: Date.now(),
              },
            },
          };
          let res = await HandleApi.contractValidation(
            this.$route.params.id,
            "web",
            this.$route.query.pass,
            webValidationData
          );
          if (res.status == 200) {
            this.$store.dispatch("unload");
            try {
              this.$store.dispatch(
                "load",
                this.$t("loadMessage.contractValidated")
              );
              let newData = this.contractContent.preagency;
              newData.newAgency = true;
              newData.preAgency = true;
              newData.preAgencyId = this.$route.params.id;
              newData.contractInfo.webValidation = {
                state: true,
                date: webValidationData.contractInfo.webValidation.date,
              };
              let res = await HandleApi.validateContract(
                this.$route.params.id,
                newData
              );
              if (res.status == 200) {
                this.$store.dispatch("unload");
                this.$store.dispatch(
                  "push_notification_message",
                  this.$t("notification.created", {
                    object: this.$t("notification.agency"),
                  })
                );
                this.$router.push("/");
              }
            } catch (err) {
              this.$store.dispatch("unload");
              this.$store.dispatch("push_error_message", err);
            }
          }
        } catch (err) {
          this.$store.dispatch("unload");
          this.$store.dispatch("push_error_message", err.response.data || err);
        }
      } else {
        try {
          this.$store.dispatch("load", this.$t("loadMessage.saving"));
          let newData = {
            webValidationData: {
              state: true,
              date: Date.now(),
            },
          };
          let res = await HandleApi.putAgencyContract(
            this.$route.params.id,
            "webValidation",
            {
              webValidation: newData.webValidationData,
              password: this.$route.query.pass,
            }
          );
          if (res.status == 200) {
            this.$store.dispatch("unload");
            this.$router.push("/");
          }
        } catch (err) {
          this.$store.dispatch("unload");
          this.$store.dispatch("push_error_message", err.response.data || err);
        }
      }
    },
    checkHeight() {
      if (
        Math.round(
          this.$refs.readText.scrollHeight - this.$refs.readText.scrollTop
        ) -
          Math.round(this.$refs.readText.clientHeight) <=
        1
      ) {
        this.contractNotRead = false;
      }
    },
  },
  async created() {
    if (this.$route.query.agency === "true") this.agency = true;
    if (this.agency) {
      try {
        let contractData = await HandleApi.getAgencyContract(
          this.$route.params.id,
          this.$route.query.from,
          this.$route.query.pass
        );
        if (contractData.status == 200) {
          this.contractContent = contractData.data;
          this.mailValidation =
            contractData.data.preagency.contractInfo.mailValidation.state;
          if (
            !this.contractContent.preagency.contractInfo.mailValidation.state &&
            !this.mailValidation
          ) {
            let newData = {
              mailValidationData: {
                state: true,
                date: Date.now(),
              },
            };
            if (this.$store.getters.userType !== "travelAssistEmployee") {
              let mailRes = await HandleApi.putAgencyContract(
                this.$route.params.id,
                "mailValidation",
                {
                  mailValidation: newData.mailValidationData,
                  password: this.$route.query.pass,
                }
              );
              if (mailRes.status == 200) {
                this.contractContent.preagency.contractInfo.mailValidation =
                  newData.mailValidationData;
                this.mailValidation = true;
                this.$store.dispatch(
                  "push_notification_message",
                  this.$t("notification.validated", {
                    object: this.$t("notification.mail"),
                  })
                );
              }
            }
          }
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    } else {
      try {
        let contractData = await HandleApi.getContract(
          this.$route.params.id,
          this.$route.query.from,
          this.$route.query.pass
        );
        if (contractData.status == 200) {
          this.contractContent = contractData.data;
          this.mailValidation =
            contractData.data.preagency.contractInfo.mailValidation.state;
          if (
            !this.contractContent.preagency.contractInfo.mailValidation.state &&
            !this.mailValidation
          ) {
            let mailValidationData = {
              state: true,
              date: Date.now(),
            };
            let mailRes = await HandleApi.contractValidation(
              this.$route.params.id,
              "mail",
              this.$route.query.pass,
              {
                contractInfo: {
                  mailValidation: mailValidationData,
                },
              }
            );
            if (mailRes.status == 200) {
              this.contractContent.preagency.contractInfo.mailValidation = mailValidationData;
              this.mailValidation = true;
              this.$store.dispatch(
                "push_notification_message",
                this.$t("notification.validated", {
                  object: this.$t("notification.mail"),
                })
              );
            }
          }
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    }
  },
};
</script>

<style scoped>
.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  max-width: 90%;
  width: 500px;
  margin: 0 auto;
  border-radius: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin: 10px auto;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.contract-display {
  height: calc(100vh - 250px);
  width: 90%;
  max-width: 800px;
}

.contract-container {
  width: 80%;
  margin: 10px auto;
  background-color: #e5e5e5;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  padding: 10px;
  border-radius: 0.25em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.read-text {
  width: 80%;
  margin: 0 auto;
  height: calc(100vh - 250px);
  overflow-y: scroll;
  background: white;
  padding: 5px 10px;
}
.contract-validation {
  width: 90%;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.mail-validation-message {
  width: 80%;
  margin: 10px auto;
  background-color: rgba(9, 201, 111, 0.8);
  padding: 5px;
  border-radius: 0.25em;
  display: flex;
  justify-content: center;
}
</style>
